import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content, { HTMLContent } from "../components/Content"

import footerImage from "../images/roadblock.png"

const ContactPageTemplate = props => {
  const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 700px;
    min-height: calc(100vh - 200px - 24px);
    
    @media (min-width: 650px) {
      min-height: calc(100vh - 105px - 24px);
    }
  `

  const Contact = styled(props.contentComponent || Content)`
    display: flex;
    font-size: 20px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-family: Roboto Condensed;

    h2 {
      display: inline-flex;
      font-family: Teko;
      color: #fff;
      background: #000;
      text-decoration: none;
      padding: 3px 20px 0;
      align-items: center;
      line-height: 1.25;
      font-size: 26px;
      font-weight: normal;
      margin: 0;
    }

    ul {
      margin-top: 50px;
      padding: 0;
      list-style: none;

      li + li {
        margin-top: 10px;
      }

      a[href^="mailto:"]::before,
      a[href^="tel:"]::before, 
      a[href*="instagram.com"]::before {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        padding: 5px;
        font-size: 25px;
        margin-right: 1em;
        color: #fff;
        border-radius: 50%;
        background: black;
        font-weight: 900;
      }

      a:hover::before {
        color: #000;
        background: #fff;
      }

      a[href^="mailto:"]::before {
        font-family: "Font Awesome 5 Free";
        content: "\f0e0";
      }

      a[href^="tel:"]::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f2a0";
      }

      a[href*="instagram.com"]::before {
        font-family: "Font Awesome 5 Brands";
        content: "\f16d";
      }
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  `
  const Footer = styled.img`
    max-width: 400px;
    width: 100%;
    height: 150px;
    object-fit: contain;
    margin-top: 65px;
  `

  return (
    <>
      <Wrapper>
        <Contact content={props.content} />
        <Footer src={footerImage} />
      </Wrapper>
    </>
  )
}

const ContactPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout withHeader backUrl="/">
      <SEO title={page.frontmatter.title} />
      <ContactPageTemplate contentComponent={HTMLContent} content={page.html} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`